<template>
	<Metier name="metier-laboratoire" title="Laboratoire" :main-menu="[{title: 'Chimiste', missions: ['Réalise des analyses chromatographiques, du pétrole et des eaux', 'Fournit les résultats d\'analyse aux clients du laboratoire', 'Participe à la sécurité du laboratoire et à l\'entretien du matériel'], competences: ['Formation en chimie', 'Connaissances des produits, des méthodes et du matériel', 'Aptitude au travail en équipe'], qualites: ['Rigueur', 'Précision', 'Esprit d\'initiative']}, {title: 'Agent Technique Laboratoire', missions: ['Assure le suivi et le contrôle des appareils du service', 'Effectue l\'étalonnage des appareils du laboratoire', 'Gère les stocks de matériel du service'], competences: ['Formation en mesures physique ou chimie', 'Connaissances en métrologie', 'Connaissance des produits, des méthodes et des outils'], qualites: ['Rigueur', 'Précision', 'Esprit d\'équipe']}, {title: 'Technicien laboratoire', missions: ['Réaliser des analyses', 'Fournir des résultats d\'analyse', 'Assurer le suivi du matériel'], competences: ['Formation en Chimie', 'Connaissances des produits, des méthodes, du matériel'], qualites: ['Précision', 'Esprit d\'initiative', 'Esprit d\'équipe']}]">
	</Metier>
</template>

<script>
	export default {
		components: {
			Metier: () => import('@/components/Metier.vue'),
		},
	}
</script>
