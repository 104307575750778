<template>
	<PopupTwoCol name="laboratoire"
	text="<h3>Laboratoire</h3><br/><p>C’est ici que chaque jour le brut, les produits et l’eau sont analysés. Il y a trois salles principales : la chromatographie, la salle d’analyse consacrée au pétrole et la salle d’analyse des eaux de la raffinerie. En moyenne, 300 analyses sont effectuées tous les jours.</p>">
	<picture slot="thumbnail__1">
		<img class="lazy"
		sizes="(max-width: 1366px) 300px,
		(max-width: 1920px) 410px,
		970px"
		data-srcset="
		img/popup/labo_clmlph_c_scale_w_350.jpg 350w,
		img/popup/labo_clmlph_c_scale_w_717.jpg 717w,
		img/popup/labo_clmlph_c_scale_w_970.jpg 970w"
		data-src="img/popup/labo_clmlph_c_scale_w_970.jpg"
		src="static/logo-total.jpg"
		alt="">
	</picture>
</PopupTwoCol>
</template>

<script>
	export default {
		components: {
			PopupTwoCol: () => import('@/components/PopupTwoCol.vue'),
		},
	}
</script>
